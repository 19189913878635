import { isNumber } from "./is-number";

export default function formatNumberLocale(
  n: string | number,
  locale = "en-US",
  decimal = 2
) {
  try {
    const parsed = typeof n === "string" ? parseFloat(n) : n;
    if (!isNumber(parsed)) return null;
    const number = (parsed * 100) / 100;

    const formattedNumber = number.toLocaleString(locale, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });

    return formattedNumber;
  } catch (error) {
    console.error(
      `Could not format number=${n}, locale=${locale}, decimal=${decimal}`,
      error
    );
    return null;
  }
}
