import { NewLogoIcon } from "@/assets/icons/general/NewLogo";
import st from "../styles/items/GamesIcons.module.scss";
import dynamic from "next/dynamic";
const CaseOpen = dynamic(() => import("../assets/icons/games/CaseOpenIcon"));
const Crash = dynamic(() => import("../assets/icons/games/Crash"));
const Dice = dynamic(() => import("../assets/icons/games/Dice"));
const Plinko = dynamic(() => import("../assets/icons/games/Plinko"));
const Roulette = dynamic(() => import("../assets/icons/games/Roulette"));
const Slots = dynamic(() => import("../assets/icons/games/Slots"));
const LiveGamesIcon = dynamic(() => import("../assets/icons/games/LiveGamesIcon"));
const SportsIcon = dynamic(() => import("../assets/icons/general/Sports"));
const BlackJack = dynamic(() => import("../assets/icons/games/Blackjack"));
const Baccarat = dynamic(() => import("../assets/icons/games/Baccarat"));
const RouletteSlots = dynamic(() => import("../assets/icons/games/RouletteSlots"));
const GameShowIcon = dynamic(() => import("../assets/icons/games/GameShowIcon"));
const MiniBattleIcon = dynamic(() => import("@assets/icons/games/MiniBattleIcon"));
const KenoIcon = dynamic(() => import("@assets/icons/games/KenoIcon"));
const MinesIcon = dynamic(() => import("@assets/icons/games/MinesIcon"));
interface GamesIconsProps {
  code?: string;
  size?: string | false;
  noWidth?: boolean;
  hasGlow?: boolean;
  hasDefaultColor?: boolean;
}

// Map of normalized game codes to their components
const GAME_ICONS = {
  caseopen: CaseOpen,
  "case-open": CaseOpen,
  crash: Crash,
  "case-battles": MiniBattleIcon,
  "double-down": MiniBattleIcon,
  dice: Dice,
  plinko: Plinko,
  roulette: Roulette,
  slots: Slots,
  "live-games": LiveGamesIcon,
  live: LiveGamesIcon,
  blackjack: BlackJack,
  baccarat: Baccarat,
  "roulette-slots": RouletteSlots,
  "game-shows": GameShowIcon,
  sports: SportsIcon,
  keno: KenoIcon,
  mines: MinesIcon,
  "mines-game": MinesIcon,
  originals: NewLogoIcon
} as const;

// Normalize game codes for consistent lookup
const normalizeGameCode = (code: string): string => {
  const normalized = code.toLowerCase().replace(" ", "-");
  if (normalized === "gold-chests" || normalized === "case-opening") {
    return "case-open";
  }
  return normalized;
};
export default function GamesIcons({
  code = "CaseOpen",
  size = false,
  noWidth = false,
  hasGlow = false,
  hasDefaultColor = false
}: GamesIconsProps) {
  const classNameItem = [size && st[size], st["value-icon"], noWidth && st["no-width"], hasGlow ? st["has-glow"] : st["no-glow"], !hasDefaultColor && st["has-custom-color"]].filter(Boolean).join(" ");
  const normalizedCode = normalizeGameCode(code);
  const IconComponent = GAME_ICONS[normalizedCode] || Slots; // Fallback to Slots

  return <div className={classNameItem} data-sentry-component="GamesIcons" data-sentry-source-file="games-icons.tsx">
      {[SportsIcon, LiveGamesIcon].includes(IconComponent) ? <IconComponent width="20" height="20" viewBox="0 0 20 20" /> : <IconComponent />}
    </div>;
}